// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/mnt1/build/mes-pc/erp/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@C/components/layout/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "redirect": "/test",
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__layout__BasicLayout' */'@/components/layout/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/test",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__test' */'@/pages/test'), loading: LoadingComponent}),
        "access": false,
        "exact": true
      },
      {
        "path": "/bpmn/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Bpmn__List' */'@/pages/Bpmn/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/warehouse",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Stock__Warehouse' */'@/pages/Stock/Warehouse'), loading: LoadingComponent}),
        "access": false,
        "exact": true
      },
      {
        "path": "/stock/storagelocation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Stock__StorageLocation' */'@/pages/Stock/StorageLocation'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/inventory/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Inventory' */'@/pages/Inventory'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/base/stockItem",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Stock__Item' */'@/pages/Stock/Item'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/bizTask",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BizTask' */'@/pages/BizTask'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/outStockTask",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BizTask__OutStockTask' */'@/pages/BizTask/OutStockTask'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/outStockTaskDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BizTask__OutStockTask__OutStockTaskDetail__detail' */'@/pages/BizTask/OutStockTask/OutStockTaskDetail/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/material/stock",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Material__Stock' */'@/pages/Material/Stock'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/material/stockDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Material__StockDetail' */'@/pages/Material/StockDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/material/color",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Material__Color' */'@/pages/Material/Color'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/material/size",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Material__Size' */'@/pages/Material/Size'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/take",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StockTake' */'@/pages/StockTake'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/take/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StockTake__Detail' */'@/pages/StockTake/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/apply/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Apply__List__index' */'@/pages/Apply/List/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/apply/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Apply__List__addApply' */'@/pages/Apply/List/addApply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/stockSumReport",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Apply__List__sumReport' */'@/pages/Apply/List/sumReport'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/apply/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Apply__List__editApply' */'@/pages/Apply/List/editApply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/apply/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Apply__List__detail' */'@/pages/Apply/List/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/balance",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Balance' */'@/pages/Balance'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/inventory",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Stock__Inventory' */'@/pages/Stock/Inventory'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/inventory/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Stock__Inventory__Add' */'@/pages/Stock/Inventory/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/inventory/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Stock__Inventory__Add' */'@/pages/Stock/Inventory/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/stock/inventory/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Stock__Inventory__Detail' */'@/pages/Stock/Inventory/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/material/unit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Materiel__Unit' */'@/pages/Materiel/Unit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/material/classification",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Classification' */'@/pages/Classification'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/material/info",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Material__Info' */'@/pages/Material/Info'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/material/info/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Material__Info__InfoAdd' */'@/pages/Material/Info/InfoAdd'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/material/info/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Material__Info__InfoDetail' */'@/pages/Material/Info/InfoDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/orderForm",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__Query' */'@/pages/OrderForm/Query'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/creator/make",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__Creator__make' */'@/pages/Purchase/Creator/make'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/orderForm/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__Add' */'@/pages/OrderForm/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/orderForm/receiveDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__ReceiveList__Detail' */'@/pages/OrderForm/ReceiveList/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/orderForm/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__Detail' */'@/pages/OrderForm/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/orderForm/receive",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__Receive' */'@/pages/OrderForm/Receive'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/receive",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__ReceiveList' */'@/pages/OrderForm/ReceiveList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/outReject",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__OutRejectList' */'@/pages/OrderForm/OutRejectList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/outReject/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__OutRejectList__Detail' */'@/pages/OrderForm/OutRejectList/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/receiveWaitList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__ReceiveWait' */'@/pages/OrderForm/ReceiveWait'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/orderForm/reject",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__Reject' */'@/pages/OrderForm/Reject'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/orderForm/reject/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__Reject' */'@/pages/OrderForm/Reject'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/orderForm/reject/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderForm__Reject' */'@/pages/OrderForm/Reject'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/procurement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Procurement__Query' */'@/pages/Procurement/Query'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/procurement/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Procurement__Add' */'@/pages/Procurement/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/procurement/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Procurement__Detail' */'@/pages/Procurement/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/procurement/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Procurement__Edit' */'@/pages/Procurement/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/sale/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Sale__Query' */'@/pages/Sale/Query'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/sale/list/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Sale__Detail' */'@/pages/Sale/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/sale/list/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Sale__Add' */'@/pages/Sale/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/materialPlan",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MaterialPlan' */'@/pages/MaterialPlan'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/inInventory",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__In' */'@/pages/Purchase/In'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/outInventory",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__Out' */'@/pages/Purchase/Out'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/statistics",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__Statistics' */'@/pages/Purchase/Statistics'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/commodity",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__Commodity' */'@/pages/Purchase/Commodity'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/overdue",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__Overdue' */'@/pages/Purchase/Overdue'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/finance/invoice",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__Invoice' */'@/pages/Finance/Invoice'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/finance/invoice/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__Invoice__Add' */'@/pages/Finance/Invoice/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/finance/invoice/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__Invoice__Add' */'@/pages/Finance/Invoice/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/finance/invoice/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__Invoice__Detail' */'@/pages/Finance/Invoice/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/finance/sale",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__Sale' */'@/pages/Finance/Sale'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/finance/sale/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__Sale__Add' */'@/pages/Finance/Sale/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/finance/sale/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__Sale__Add' */'@/pages/Finance/Sale/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/finance/sale/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__Sale__Detail' */'@/pages/Finance/Sale/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/sale/cost",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Sale__Cost' */'@/pages/Report/Sale/Cost'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/sale/cost/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Sale__Cost__detail' */'@/pages/Report/Sale/Cost/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/process",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__Process' */'@/pages/Purchase/Process'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/process/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__Process__Add' */'@/pages/Purchase/Process/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/process/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__Process__Add' */'@/pages/Purchase/Process/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/purchase/process/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Purchase__Process__Detail' */'@/pages/Purchase/Process/Detail'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
