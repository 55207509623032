
export default {
  unit: {
    addUnit: '新建单位',
    editUnit: '编辑单位',
    unitName: '单位名称',
    unitCode: '单位编码',
    message: {
      formUnitName: '请输入单位名称',
      formUnitCode: '请输入单位编码',
      formExpression: '请输入转换值',
      formToId: '请选择转换单位',
      unitExistence: '该单位名称已存在',
    },
    button: {
      addCmd: '添加换算关系'
    }
  }
};
