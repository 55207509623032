export default {
  'StockApplyType.IN': '入库',
  'StockApplyType.OUT': '出库',
  'StockApplyType.MOVE': '移库',
  // 'StockApplyType.IN_MM': '采购入库',
  // 'StockApplyType.OUT_MM': '采购出库',
  // 'StockApplyType.CMIN': '采购入库',
  'ApplyState.WAITING': '等待中',
  'ApplyState.RUNNING': '待审核',
  'ApplyState.COMPLETED': '已审核',
  'ApplyState.TERMINATE': '已作废',
  'ApplyState.HAND_COMPLETED': '已完成',
  'MMBizTaskApplyType.UNIFIED': '统一交期',
  'MMBizTaskApplyType.MANY_UNIFIED': '多交期',
  'InStatus.COMPLETED': '已完成',
  'InStatus.RUNNING': '入库中',
  'InStatus.TERMINATE': '已作废',
  'InStatus.WAITING': '待入库',
  'OutStatus.COMPLETED': '已完成',
  'OutStatus.RUNNING': '出库中',
  'OutStatus.TERMINATE': '已作废',
  'OutStatus.WAITING': '待出库',
  ProcurementStatus: {
    ON_THING: '未采购',
    ANY: '部分采购',
    ALL: '全部采购',
    OVER: '超出采购',
  },

  InventoryStockApplyType: {
    IN: '新建入库',
    OUT: '新建出库',
    MOVE: '移库',
    IN_MM: '采购入库',
    OUT_MM: '采购退货',
    IN_IE: '退料管理',
    OUT_IE: '补料管理',
    OUT_IE_CC: '生产领料',
    UNCHANGED: '不变',
    INVENTORY_SURPLUS: '盘盈',
    INVENTORY_LOSS: '盘亏',
  }
}
