
export default {

  add: {
    info: {
      'createUserName': '申请人',
      'createdAt': '申请时间',
      'typeName': '申请类型',
      'wareHouse': '仓库',
      'relationType': '单据类别',
      'state': '状态',
      'applyNum': '申请单号',
      'type': '类型',
      'outWareHouse': '出库仓库',
      'inWareHouse': '入库仓库',
      'inNum': '已入库数量',
      'outNum': '已出库数量',
      'supplier': '供应商',
      'moveNum': '已移库数量',

      supplierCode: '供应商编码',
      supplierName: '供应商名称',
      deliveryTypeId: '交期类型id',
      deliveryTypeName: '交期类型',
      deliveryTime: '交货日期',
      remark: '备注',
      attachment: '附件',
      relationCode: '关联单据',
      orderCode: '申请单号',

      itemId: '物料ID',
      itemCode: '物料编码',
      itemName: '物料名称',
      itemCount: '入库数量',
      beforeStockNumber: '入库前数量',
      percent: '进度',
      applyState: '状态',
      receiveCode: '收货单号',
      receivedAt: '收货日期',
      supplierDeliveryCode: '供应商送货单号',
      rejectCode: '退货单号',
      rejectAt: '退货日期',
    },
  },
  detail: {
    tabPane: {
      tabDetail: '详情',
      tabReceive: '收货记录',
      tabReject: '退货记录',
    },
    card: {
      titleWait: '待收货',
      titleReceive: '收货记录',
    }
  },
  common: {
    addReceive: '创建采购入库单',
    button: {
      reject: '退货',
    }
  }
};
