export default {

  stockItemModel: {
    attributes: '物料颜色',
    itemCode: '物料编码',
    itemName: '物料名称',
    itemTypeCode: '物料分类编码',
    itemTypeName: '物料分类',
    maxNumber: '最大数',
    rowState: '状态',
    safeNumber: '安全数',
    typeCode: '库件类型',
    createdAt: '创建时间',
    enabledNumber: '可用库存',
    occupyNumber: '占用数',
    posName: '库位',
    posCode: '库位编码',
    posId: '库位ID',
    stockNumber: '库存数量',
    warehouseName: '仓库',
    warehouseCode: '仓库编码',
    warehouseId: '仓库ID',
    color: '颜色',
    numIndex: '序号',
    fromItemUnitId: '单位',
    unitName: '申请单位',
    toItemUnitId: '单位',
    itemSize: '物料尺码',
    itemUnit: '物料单位',
    itemColor: '物料颜色',
  },

  bizTaskModel: {
    bizCode: '申请单号',
    changeNumber: '数量',
    createdAt: '创建时间',
    id: '库件编码',
    itemCode: '物料编码',
    itemId: '物料名称',
    itemName: '物料名称',
    posCode: '库位编码',
    posId: '库位ID',
    posName: '库位',
    stockNumber: '库存数量',
    taskId: '库存任务',
    typeCode: '类型编码',
    typeName: '类型名称',
    warehouseCode: '仓库编码',
    warehouseId: '仓库',
    warehouseName: '仓库名称',
    attachment: '附件',
    remark: '备注',

    inPosId: '入库库位',
    inStockNumber: '入库数量',
    inWarehouseCode: '入仓库编码',
    inWarehouseId: '入库仓库',
    inWarehouseName: '入仓库名称',
    outPosId: '出库库位',
    outStockNumber: '出库数量',
    outWarehouseCode: '出仓库编码',
    outWarehouseId: '出库仓库',
    outWarehouseName: '出仓库名称',

    description: '批次号',
    inBeforeStockNumber: '入库前数量',
    outBeforeStockNumber: '出库前数量',
    planNumber: '已入/计划',
    planOutNumber: '已出/计划',
    planRemoveNumber: '已移库/计划',
    createUserName: '申请人',

    itemTypeCode: '物料分类编码',
    itemTypeName: '物料分类',

    attributes: '属性',
    enabledNumber: '可用数量',
    maxNumber: '最大数',
    occupyNumber: '占用数量',
    rowState: '状态',
    safeNumber: '安全数',
    outChangeNumber: '本次出库数量',
    itemSize: '尺码',
    itemUnit: '单位',
    itemColor: '颜色',
    materialCode: '物料编码',
    outMMBizCode: '退货单号',
    inMMBizCode: '收料单号',
    supplierName: '供应商',
    outIEBizCode: '补料单号',
    inIEBizCode: '退料单号',
    partName: '部位',
  },

  wareHouseModel: {
    warehouseCode: '仓库编码',
    warehouseName: '仓库名称',
    typeCode: '仓库类型',
    typeName: '仓库类型',
    address: '仓库地址',
    specification: '规格',
    remark: '备注',
    factoryId: '工厂id',
    factoryCode: '工厂编码',
    factoryName: '仓库所属',
    rowState: '状态',
    createdAt: '创建时间',
  },
  storageLocationModel: {
    posCode: '库位编码',
    posName: '库位名称',
    warehouseId: '仓库id',
    warehouseName: '仓库名称',
    remark: '备注',
    rowState: '状态',
    createdAt: '创建时间',
  },
  InventoryListModel: {
    inventoryCode: '出入库单号',
    finishedAt: '出入库日期',
    typeName: '类型',
    itemCode: '物料编码',
    itemName: '物料名称',
    attributes: '物料颜色',
    warehouseName: '仓库',
    stockNumber: '数量',
    createUserName: '操作人',
    remark: '备注',
    accessories: '附件',
    bizCode: '关联单据',
    surplus: '库存结余',
    color: '颜色',
    itemSize: '尺码',
    itemUnit: '单位',
    itemColor: '颜色',
    supplierCode: '供应商编码',
    supplierName: '供应商',
    unitPrice: '单价',
    amountOfMoney: '金额',
    supplierDeliveryCode: '供应商送货单号',
    state: '状态',
    rowState: '状态',
  },
  ApplyListModel: {
    order: '申请单',
    applyPeople: '申请人',
    type: '类型',
    state: '状态',
    percent: '进度',
    relevance: '关联单据',
    applyDate: '申请时间',
  },
  StockApply: {
    material: '物料编号',
    materialName: '物料名称',
    sort: '物料分类',
    number: '申请数量'
  },
  StockApplyModel: {
    index: '序号',
    material: '物料编号',
    materialName: '物料名称',
    sort: '物料分类',
    number: '申请数量',
    inNumber: '入库数量',
    createUserName: '申请人',
    relationCode: '关联单据',
    applyState: '状态',
    bizCode: '申请单号',
    posId: '库位',
    remark: '备注',
    rowState: '状态',
    typeCode: '类型编码',
    typeName: '类型',
    beforeStockNumber: '已完成数量',
    bizTaskChange: '批次号',
    // 指的是物料库存
    itemCode: '物料编码',
    itemName: '物料名称',
    stockNumber: '申请数量',
    taskApplyId: '任务申请',
    attachment: '附件',
    itemTypeName: '物料分类',
    description: '批次号',
    createdAt: '申请日期',
    inBeforeStockNumber: '入库前数量',
    planNumber: '已入/计划',
    planOutNumber: '已出/计划',
    planRemoveNumber: '已移库/计划',
    inPosId: '入库库位',
    detailStockNumber: '已出/已入数量',
    inWarehouseId: '入库仓库',
    inWarehouseName: '仓库',
    outBeforeStockNumber: '出库前数量',
    outPosId: '出库库位ID',
    outStockNumber: '出库数量',
    outWarehouseId: '出库仓库',
    percent: '进度',
    color: '颜色',
    fromItemUnitId: '单位',
    unitName: '单位',
    // 一般用户选择的是这个值
    toItemUnitId: '单位',
    itemSize: '尺码',
    materialCode: '物料编码',
  },
  ClassificationModel: {
    dataCode: '分类编码',
    dataName: '分类名称',
    parentId: '上级分类',
    remark: '备注',
    rowState: '状态',
    sortNumber: '排序',
    sizeCode: '编码',
    sizeName: '尺码名称',
  },

  ColorModel: {
    colorCode: '颜色编码',
    colorId: '颜色名称',
    colorName: '颜色名称',
    materialCode: '材料编码',
    materialId: '材料Id',
    remark: '备注',
    rgbValue: 'RGB',
    cmykValue: 'CMYK',
    hexValue: 'HEC',
    hsvValue: 'HSX',
    pantoneValue: '潘通色',
    colorDiv: '颜色',
    rowState: '状态',
  },

  MaterialInfoModel: {
    attachment: '附件',
    images: '图片',
    materialCode: '物料编码',
    materialName: '物料名称',
    remark: '备注',
    rowState: '状态',
    typeId: '分类ID',
    typeName: '物料分类',
    unitId: '单位ID',
    unitName: '物料单位',

    colorCode: '颜色编码',
    colorId: '颜色Id',
    colorName: '颜色名称',
    materialId: '材料ID',
    rgbValue: 'RGB',

    supplierCode: '供应商编码',
    supplierId: '供应商Id',
    supplierName: '供应商名称',

    itemCode: '物料编码',
    itemName: '物料名称',
    itemTypeCode: '物料分类编码',
    itemTypeName: '物料分类名称',
    maxNumber: '最大数',
    posCode: '默认库位编码',
    posId: '默认库位Id',
    safeNumber: '安全数',
    warehouseCode: '默认仓库编码',
    warehouseId: '默认仓库ID',
    spec: '规格',
  },
  UnitModel: {
    fromId: '单位ID',
    fromToExpression: '单位与转换单位关系',
    remark: '备注',
    toFromExpression: '转换单位与单位关系',
    toId: '转换单位ID',
    rowState: '状态',
    typeCode: '类型',
    unitCode: '单位编码',
    unitName: '单位名称',
  },
  mmBizTaskApplyModel: {
    supplierCode: '供应商编码',
    supplierName: '供应商',
    deliveryTypeId: '交期类型',
    deliveryTypeName: '交期类型',
    deliveryTime: '交货日期',
    remark: '备注',
    attachment: '附件',
    relationCode: '单据号',
    orderCode: '采购单号',
    createUserName: '申请人',

    itemId: '物料ID',
    itemCode: '物料编码',
    itemName: '物料名称',
    itemCount: '采购数',
    beforeStockNumber: '入库前数',
    createdAt: '创建日期',
    percent: '进度',
    applyState: '状态',
    numIndex: '序号',
    colorName: '颜色',
    mmInCount: '采购数',
    itemTypeName: '物料分类',
    stockCount: '已收料数',
    stockNum: '实际收料数',
    itemApplyCount: '申请数',
    itemApplyCountStockCount: '已申请/采购数',
    itemCountItemApplyCount: '采购数/申请数',
    receiveCount: '本次收料',
    // receiveCount: '申请收料数',
    fromItemUnitId: '申请单位',
    unitName: '申请单位',
    toItemUnitId: '单位',
    currencyType: '货币类型',
    currencyName: '货币名称',
    itemSize: '尺码',
    itemColor: '颜色',
    itemUnit: '单位',
    rejectCount: '退料数',
    saleCode: "指令单号",
    styleCode: '款号',
    stageName: '采购阶段',
    stageCode: '采购阶段编号',
    warehouseName: '仓库',
    warehouseId: '仓库',
    unitPrice: '单价',
    amountOfMoney: '金额',
  },
  RejectModel: {
    attachment: '附件',
    orderCode: '采购单号',
    orderId: '收料单号',
    receivedAt: '收货日期',
    remark: '备注',
    rowState: '状态',
    skTaskCode: '库存任务单号',
    skTaskId: '库存任务ID',
    supplierDeliveryCode: '供应商送货单号',
    supplierName: '供应商',
    supplierCode: '供应商编码',

    itemId: '物料ID',
    itemCode: '物料编码',
    itemName: '物料名称',
    itemCount: '采购数量',
    beforeStockNumber: '入库前数',
    createdAt: '创建日期',
    percent: '进度',
    applyState: '状态',
    numIndex: '序号',
    colorName: '颜色',
    mmInCount: '采购数量',
    itemTypeName: '物料分类',
    orderReceiveCode: '收料单号',
    receiveCount: '申请退料数',
    skTaskDetailId: '库存任务明细ID',
    stockCount: '实际退料数',
    receiveCode: '收料单号',
    itemApplyCount: '已收料申请数',
    rejectCount: '退料数量',
    orderRejectCode: '退料单号',
    rejectCode: '退料单号',
    receiveApplyCount: '申请收料数',
    itemSize: '尺码',
    itemColor: '颜色',
    itemUnit: '单位',
    outRowState: '状态',
    stockNum: '收料数',
    saleCode: '指令单号',
    styleCode: '款号',
    createUserName: '创建人',
    warehouseName: '仓库',
    unitPrice: '单价',
    amountOfMoney: '金额',
    financialAudit: '财务审核状态',
  },

  ProcurementModel: {
    attachment: '文件',
    createdAt: '创建日期',
    deliveryTime: '计划交货日期',
    listCode: '采购申请号',
    listCount: '计划采购数量',
    listOrderCount: '已采购数量',
    materialCode: '物料编码',
    materialDimensionId: '物料维度ID',
    materialDimensionTree: '物料维度层级信息',
    materialId: '物料ID',
    materialName: '物料名称',
    orderTime: '计划下单日期',
    remark: '备注',
    rowState: '采购状态',
    //源单号
    sourceCode: '指令单号',
    styleCode: '款号',
    supplierCode: '供应商',
    supplierName: '供应商',
    listOrderCountListCount: '已采购/计划数',
    itemSize: '尺码',
    itemColor: '颜色',
    saleCode: "指令单号",
    procurementCode: "采购申请号",
    applyType: "申请类型",
    applyName: "申请类型",
    relationSourceCode: "关联指令单",
    relationStyleCode: "关联款色",
    createUserName: "创建人",
    relationRowState: '状态',
  },

  SaleModel: {
    attachment: '附件',
    clientCode: '客户编码',
    clientName: '客户名称',
    createdAt: '创建时间',
    deliveryTime: '交货日期',
    dutyUserCode: '负责人编码',
    dutyUserName: '负责人',
    orderTime: '下单日期',
    orderTypeId: '订单类型id',
    orderTypeName: '订单类型',
    remark: '备注',
    rowState: '状态',
    saleCode: '订单单号',
    // 销售数量
    saleCount: '订单数量',

    colorCode: '颜色编码',
    colorName: '颜色',
    saleId: '销售id',
    //销售项目数量
    saleItemCount: '订单数量',
    sizeCode: '尺码编码',
    sizeName: '尺码',
    styleCode: '款号',
    //款式
    styleName: '品名',
    styleId: '款式ID',
    instructCode: '指令单号',
    prepareCount: '预补数量',
    makeCount: '下发数量',
    type: '类型',
    totalNub: '合计',
    unitPrice: '单价',
    sumMoney: '金额',
  },

  salePlan: {
    // 主表
    createdAt: '创建时间',
    rowState: '状态',
    //销售单号-指令单号
    saleCode: "客户订单",
    instructCode: "指令单号",
    clientCode: "客户编码",
    clientName: "客户",
    deliveryTime: "交货日期",
    orderTime: "下单日期",
    orderTypeId: "订单类型id",
    orderTypeName: "订单类型名称",
    dutyUserCode: "负责人编码",
    dutyUserName: "负责人名称",
    //销售数量
    saleCount: "数量",
    remark: '备注',
    attachment: "附件",

    //子表 
    styleCode: '款号',
    colorName: '颜色',
    sizeName: '尺码',
    materialCode: '材料编码',
    materialName: '材料名称',
    consumption: '用量',
    spec: '规格',
    waste: '耗损%',
    needCount: '需求量',
    stockCount: '库存数量',
    needEdCount: '需采数量',
    unitId: '单位id',
    unitName: '单位',
    supplierName: '供应商'
  },

  make: {
    rowState: '状态',
    makeCode: "生产单号",
    factoryCode: "工厂编码",
    factoryName: "生产工厂",
    deliveryTime: "交货日期",
    //款式编码
    styleCode: "款号",
    //款式名称
    styleName: "品名",
    colorCode: "颜色编码",
    colorName: "颜色",
    shoeLast: "楦头",
    heelpiece: "后跟",
    outsole: "大底",
    orderTypeId: "订单类型id",
    orderTypeName: "订单类型",
    saleId: "销售单号-客户订单Id",
    //销售单号-客户订单
    saleCode: "客户订单",
    remark: "备注",
    attachment: "附件",

    saleItemId: "销售单项目id",
    sizeCode: "尺码编码",
    sizeName: "尺码",
    //销售项目数量
    saleItemCount: "订单数量",
    prepareCount: "预补数量",
    instructCode: "指令单号",
  },
  mtMaterial: {
    dataCode: '编码',
    dataName: '名称',
    parentId: '父级ID',
    remark: '备注',
    rowState: '状态',
    sortNumber: '排序',
  },

  StockApplySumReportModel: {
    orgName: '部门',
    applyAt: '申请日期',
    typeName: '申请类型',
    itemCode: '物料编码',
    itemName: '物料名称',
    color: '颜色',
    itemSize: '尺码',
    stockNumber: '数量',
    unitName: '单位',
    unitPirce: '单价',
    totalPrice: '金额',
    warehouseName: '仓库',
    createUserName: '创建人',

  }
};
