// @ts-nocheck

import { useState, useCallback } from 'react';

export default () => {
  const [ state, setState ] = useState({
    menuCollapsed: false,
    setMenuCollapsed: v => {
      setState(s => ({ ...s, menuCollapsed: v }))
    },
    confEditorVisible: false,
    setConfEditorVisible: v => {
      setState(s => ({ ...s, confEditorVisible: v }))
    },
    confKey: '',
    setConfKey: v => {
      setState(s => ({ ...s, confKey: v }))
    },
    confSchema: '',
    setConfSchema: v => {
      setState(s => ({ ...s, confSchema: v }))
    },
  });

  const setModel = useCallback(model => {
    setState(s => ({ ...s, model }));
  }, []);

  return {
    model: state,
    setModel
  };
}
