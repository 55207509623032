
import { useSelector } from 'umi';
import { IModel, ModelAction, ModelDispatch, ModelReducer, ModelState, ModelStateSelector } from './model';

export interface StorageModelState extends ModelState {
  baseInfo: any;
  baseResources: any[];
}

export interface IStorageModel extends IModel<StorageModelState> {
  effects: {};

  reducers: {
    select: ModelReducer<StorageModelState>
  };
}

type ActionKeys = keyof IStorageModel["effects"] | keyof IStorageModel["reducers"]

export type StorageModelAction = ModelAction<ActionKeys, any>;

export type StorageModelDispatch = ModelDispatch<StorageModelAction, any>;

const selector: ModelStateSelector<StorageModelState> = (state: any) => state.storage;

export function useStorageState(): StorageModelState {
  return useSelector(selector);
}

const StorageModel: IStorageModel = {
  state: {
    baseInfo: {},
    baseResources: []
  },
  effects: {
  },
  reducers: {
    select(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
  }
};

export default StorageModel;
