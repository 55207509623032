
import { useSelector } from 'umi';
import { IModel, ModelAction, ModelDispatch, ModelEffect, ModelReducer, ModelState, ModelStateSelector } from './model';

export interface UserModelState extends ModelState {
  tid: number;
  token: string;
  userInfo: any;
  apps: any[];
  resources: any[];
  roles: any[];
  appRoles: any[];
  appResources: any[];
}

export interface IUserModel extends IModel<UserModelState> {
  effects: {};

  reducers: {
    save: ModelReducer<UserModelState>
  };
}

type ActionKeys = keyof IUserModel["effects"] | keyof IUserModel["reducers"]

export type UserModelAction = ModelAction<ActionKeys, any>;

export type UserModelDispatch = ModelDispatch<UserModelAction, any>;

const selector: ModelStateSelector<UserModelState> = (state: any) => state.user;

export function useUserState(): UserModelState {
  return useSelector(selector);
}

const UserModel: IUserModel = {
  state: {
    tid: 0,
    token: '',
    userInfo: {},
    apps: [],
    resources: [],
    roles: [],
    appRoles: [],
    appResources: []
  },
  effects: {
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    }
  }
};

export default UserModel;
