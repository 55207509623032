
export default {
  info: {
    placeholder: {
      searchWarehouse: '搜索仓库',
    },
    searchAll: '全部',
  },
  card: {
    itemColor: '颜色/尺码',
    stock: '库存',
    supplyChain: '供应链',
    annexInfo: '附件消息',
    button: {
      color: '颜色',
      size: '尺码',
      addColor: '添加颜色',
      addSize: '添加尺码',
    }
  },
  infoAdd: {
    button: {
      addColor: '添加颜色',
    },

    attachment: '附件',
    images: '图片',
    materialCode: '物料编码',
    materialName: '物料名称',
    remark: '备注',
    rowState: '状态',
    typeId: '分类ID',
    typeName: '物料分类',
    unitId: '单位ID',
    unitName: '基本单位',

    colorCode: '颜色编码',
    colorId: '颜色Id',
    colorName: '颜色名称',
    materialId: '材料ID',
    rgbValue: 'RGB',

    supplierCode: '供应商编码',
    supplierId: '供应商Id',
    supplierName: '供应商',

    itemCode: '物料编码',
    itemName: '物料名称',
    itemTypeCode: '物料分类编码',
    itemTypeName: '物料分类名称',
    maxNumber: '最大库存数',
    posCode: '默认库位编码',
    posName: '默认库位',
    safeNumber: '安全库存数',
    warehouseCode: '默认仓库编码',
    warehouseName: '默认仓库',
    unitStock: '库存单位',
    unitPurchase: '采购单位',

    securityStock: '安全库存',
    spec: '规格',
  },

  classification: {
    modal: {
      confirmDelete: {
        anyContext: `确定删除已选的{key}项物料分类？`
      }
    },
    span: {
      selectContext: '已选{key}项',
    },
    addLowerLevel: '新建下级'
  }
};
