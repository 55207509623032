
export default {

  // 库存申请
  'SK20013': '存在已入库/已出库的行项目，不允许作废',
  'SK20014': '存在未完成的行项目，不允许完成',
  'SK20024': '输入的数量加上已入数的总和不能大于计划数',
  'SK20023': '申请单库存任务已开始，不允许作废申请单',
  'BD_20080': '物料颜色名称已经存在',
  'MM20010': '该采购订单没有生成收料单，不可完成',
  'MM20030': '计算错误，请检查对应bom',
  'MT_40060': '物料尺码已存在',
  'UO_30310': '供应商或客户已存在',
  'MM20020': '计算错误，请检查对应bom',
  'MM1011': '收料数不能大于采购数',
  'SK20100': '已存在库件',
  'PD20100': 'Bom存在物料颜色',
  // 'MM1020': '关联指令单或关联款色已创建有相同单据，是否继续创建',
};
