
import React from "react";
import _isString from 'lodash/isString';
import _isFunc from 'lodash/isFunction';
import _isBoolean from 'lodash/isBoolean';

export type AccessItemType = {
  [key: string]: boolean | ((args?: any) => boolean);
};

export type AccessInstanceKey = string | ((accessItems: typeof AccessItems) => boolean);

export type AccessInstanceType = (key: AccessInstanceKey, group?: string, args?: any) => boolean;

export type AccessItemsType = {
  roles: AccessItemType;
  resources: AccessItemType;
  funcs: AccessItemType;
  enabled: {
    [key: string]: boolean;
  };
  [key: string]: AccessItemType;
}

export let AccessItems: AccessItemsType = {
  roles: {},
  resources: {},
  funcs: {},
  enabled: {}
};

export const AccessContext = React.createContext<{
  check: AccessInstanceType
}>({
  check: () => true
});

export type AccessFactoryUserType = { appRoles: any[], appResources: any[] };

function findParent(code: string) {
  return code?.split(':')?.[0];
}

export default function AccessFactory(user: AccessFactoryUserType, configs?: {
  funcs?: string[];
}): AccessInstanceType {
  AccessItems = {
    roles: {},
    resources: {},
    funcs: (configs?.funcs || []).reduce((pre: any, cur: any) => {
      pre[cur] = false;
      return pre;
    }, {}),
    enabled: {}
  };

  user.appRoles.forEach(item => {
    AccessItems.roles[item.roleCode] = true;
  });

  user.appResources.forEach(item => {
    AccessItems.resources[item.resourceCode] = true;

    if (item.typeCode === 'FUMCTION') {
      AccessItems.funcs[item.resourceCode] = true;

      const p = findParent(item.resourceCode);
      if (p) {
        AccessItems.enabled[p] = true;
      }
    }
  });

  return (key, group = 'resources', args) => {
    if (_isFunc(key)) {
      return key(AccessItems);
    } else if (_isString(key)) {
      const p = findParent(key);
      if (p) {
        if (!AccessItems.enabled[p]) {
          return true;
        }

        console.log('access: ', p, group, key);
        const item = AccessItems[group][key];
        if (_isFunc(item)) {
          return item(args) === true;
        } else if (_isBoolean(item)) {
          return item === true
        }

        return false;
      }
    }

    // 按钮权限为true为全部开放
    return true;
  }
}
